<template>
    <CCol md="12" lg="12">
        <select-shop :loadingButton="loadingButton" v-on:getData="getStoreIntagrationAble"></select-shop>
        <div >
            <CCard>
               <CCardBody>
                <CRow class="mt-3">
                    <CCol md="12" class="text-center">
                        <h2 class="font-weight-medium text-dark">
                        {{ $t('grabIntegration') }}  <img src="/img/delivery/grab.png" style="width: 32px; height:32px; margin-right: 8px;"/>
                        </h2>
                        <p v-html="$t('grabIntergrationDes')" class="text-description text-muted mb-0"></p>
                    </CCol>
                </CRow>
                <hr />
                <div class="text-center" v-if="!valid"> 
                    <img src="/img/delivery/grab_integration_info.png"  style=" width: -webkit-fill-available"  />
                </div>
                <div  v-else >
                    <CAlert  color="warning" class="d-inline-block col-12 mt-1 mb-3">
                        <div class="d-flex">
                            <div class="flex-shrink-0">
                                <i class="fi fi-rr-info mr-1 text-warning"></i>
                            </div>
                            <div class="flex-grow-1 ms-3 ml-2">
                                <p class="m-0">{{ $t('grabIntergrationDes1') }}</p>
                                <p class="m-0"> * {{ $t('grabIntergrationDes2') }}</p>
                                <p class="m-0"> ** {{  $t('grabIntergrationDes3') }}</p>
                                <p class="m-0"> *** {{ $t('grabIntergrationDes4') }}</p>
                                <p class="m-0"> **** {{ $t('grabIntergrationDes5') }}</p>
                            </div>
                        </div>
                    </CAlert>
                    <CAlert  color="danger" class="d-inline-block col-12 mt-1 mb-3" :show.sync="isshowAlert" closeButton>
                        <div class="d-flex">
                            <div class="flex-grow-1 ms-3 ml-2">
                                <p class="m-0">{{ errormessage}}</p>
                            </div>
                        </div>
                    </CAlert>
                    <div class="row justify-content-md-center mt-4"  v-if="!isNext " >
                        <div class="col-lg-8 col-md-12 col-sm-12  col-12" >
                            <CCard class="card-info p-4">
                                <CAlert  color="danger" class="d-inline-block col-12 mt-0 mb-0">
                                    <div class="d-flex">
                                        <div class="flex-grow-1 ms-3 ml-2">
                                            <h5 class="mb-1">{{ $t('warningBeforeIntergration')}}</h5>
                                            <p class="m-0">{{ $t('warningBeforeIntergrationDes')}}</p>
                                        </div>
                                    </div>
                                </CAlert>
                                <div class="text-center mt-4 mb-4">
                                    <img src="/img/delivery/exportgrabb.png" style="width: 80%;text-align: center;" />
                                </div>
                                <CButton block color="success" v-on:click="isNext = true">{{ $t('next') }}</CButton>
                            </CCard>
                        </div>
                    </div>
                    <div class="row justify-content-md-center mt-4"  v-if="isNext">
                        <div class="col-lg-8 col-md-12 col-sm-12 col-12" v-if="activationUrl === ''">
                            <CCard class="card-info">
                                <CCardBody>
                                    <h5 class="font-weight-normal text-dark mb-0 ">
                                        {{ $t("information") }} <img src="/img/delivery/grab.png" style="width: 32px; height:32px; margin-right: 8px;"/>
                                    </h5>
                                    <CRow >
                                        <CCol md="12" class="form-group row">
                                        <label class="col-sm-4 col-form-label text-right text-dark w-100 w-sm-auto">
                                            <b class="text-danger"></b> {{ $t('shopname') }}
                                        </label>
                                        <div class="col-sm-8">
                                            <CInput  :placeholder="$t('shopname')" v-model="shop.shopName" disabled></CInput>
                                        </div>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol md="12" class="form-group row">
                                        <label class="col-sm-4 col-form-label text-right text-dark w-100 w-sm-auto">
                                            <b class="text-danger"></b> {{ $t('branchName') }}
                                        </label>
                                        <div class="col-sm-8">
                                            <CInput  :placeholder="$t('branchName')" v-model="shop.branchName" disabled></CInput>
                                        </div>
                                        </CCol>
                                    </CRow>
                                    <CRow v-if="merchantID !== ''">
                                        <CCol md="12" class="form-group row">
                                        <label class="col-sm-4 col-form-label text-right text-dark w-100 w-sm-auto">
                                            {{ $t('Grabfood Merchart ID') }}
                                        </label>
                                        <div class="col-sm-8" v-if="merchantID !== ''">
                                            <CInput  :placeholder="$t('Grabfood Merchart ID')"  v-model="merchantID" disabled></CInput>
                                        </div>
                                        </CCol>
                                    </CRow>
                                    <CAlert  color="danger" class="d-inline-block col-12 mt-1 mb-0" v-if="!isGrabOrderEnabled">
                                        <p class="text-danger mb-0">** {{$t('grabIntergrationDes5')}}  {{ $t('grabIntergrationDes6') }}</p>
                                    </CAlert>
                                    <CButton style="width: 100%" color="success"  v-if="isAsiaBangkok"  v-on:click="setStoreIntagration"  :disabled="isGrabOrderEnabled" > {{ $t('next') }}</CButton>
                                
                                </CCardBody> 
                            </CCard>
                        </div>
                        <div class="col-lg-12 text-center" v-else>
                            <CCard>
                                <CCardBody class="web-view-container">   
                                    <iframe
                                    :src="activationUrl"
                                    class="emptyGif"
                                    frameborder="0"  
                                    height="600" width="100%"
                                    ></iframe>
                                </CCardBody>
                            </CCard>
                        </div>
                    </div>
                </div>
               </CCardBody>
            </CCard>
          
        </div>
    </CCol>
  </template>
  
  <script>
  import { mapGetters } from 'vuex'
  import local from '@/services/local'
  
  export default {
    data() {
      return {
        isGrabOrderEnabled : false,
        loadingButton: true,
        loading: true,
        merchantID :  '',
        activationUrl : '',
        errormessage : '',
        isshowAlert : 0,
        isNext :  false,
        valid :  false
      }
    },
    created() {
        this.getStoreIntagrationAble();
        // this.getOrderShopSetting()
    },
    mounted() {

    },
    watch: {
        shopObjectId(newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                // this.getStoreConfiguration(); 
                this.isGrabOrderEnabled = false
                this.getStoreIntagrationAble()
            }
        },
    },
    computed: {
        ...mapGetters(['shops', 'users', 'date', 'shopsetting']),
        shopObjectId: {
            get() {
                return this.$store.getters.shopObjectId
            },
            set(newValue) {
                return this.$store.dispatch('setShop', newValue)
            },
        },
        currentPath() {
            return this.$route.name
        },
        shop() {
            let shop = this.shops.find((i) => i.objectId === this.shopObjectId);
            return shop;
        },
        progressWidth() {
            return (this.activeStep / this.steps.length) * 100;
        },
        isAsiaBangkok() {
            return this.shop.timezone === "Asia/Bangkok";
        }
    },
    methods: {
        activateTab(index) {
            document.querySelectorAll('.step, .tab').forEach(el => el.classList.remove('active'));
            document.querySelectorAll('.step')[index].classList.add('active');
            document.querySelectorAll('.tab')[index].classList.add('active');
        },
      setShop() {
        this.getStoreConfiguration();
      },
      getStoreConfiguration(){
        const shopObjectId = this.shopObjectId; 
        const headers = { shopObjectId }; 
       
        local.get('/api/v3.0/grab/store', { headers })
        .then((res) => {
            if(res.data.data.merchantID !== undefined && res.data.data.merchantID !== null){
                this.merchantID = res.data.data.merchantID;
                this.valid = true
                if(res.data.data.merchantID){
                    this.getOrderShopSetting()
                }
                // this.getOrderShopSetting()
            }else{
                this.merchantID = '';
                // this.valid = false
            }
        })
        .catch((error) => {
            console.error("Error fetching sales hours data:", error);
        })
        .finally(() => {
            this.loading = true;
            this.loadingButton = true;
        });
      },
      setStoreConfiguration(){
        const shopObjectId = this.shopObjectId; 
        const headers = { shopObjectId }; 
        const data = {
            merchantID :  this.merchantID
        }
        local({
                method: 'POST',
                headers: headers,
                url: '/api/v3.0/grab/store',
                data: data,
            })
            .then((res) => {
                if(res.data.error.code === 0){
                   this.setStoreIntagration()
                }else{
                    this.errormessage = res.data.error.message;
                    this.isshowAlert = 3
                }       
            })
            .catch((error) => {
                console.error("Error", error);
                this.data = [];
            })
            .finally(() => {
                this.loading = true;
                this.loadingButton = true;
            });
      },
      setStoreIntagration(){
        const shopObjectId = this.shopObjectId; 
        const headers = { shopObjectId };
        const data = {
            shopObjectId :  this.shopObjectId
        }
        let newTab = window.open('', '_blank');

        local({
                method: 'POST',
                headers: headers,
                url: '/api/v3.0/grab/store/integration',
                data: data,
            })
            .then((res) => {
                if(res.data.error.code === 0){
                   let activationUrl  = res.data.data.activationUrl;
                   if (activationUrl && newTab) {
                        // ✅ Update the blank tab with the activation URL
                        newTab.location.href = activationUrl;
                        this.isGrabOrderEnabled = true;
                    } else {
                        newTab.close(); // Close the tab if the URL is invalid
                    }
                }else{
                    this.activationUrl = ''
                    this.errormessage = res.data.error.message;     
                    this.isshowAlert = 3
                } 
            })
            .catch((error) => {
                console.error("Error  data:", error);
                this.data = [];
            })
            .finally(() => {
                // let url ='https://silompos-dev.firebaseapp.com/grabintegration'
                //    window.open(url, '_blank');
                this.loading = true;
                this.loadingButton = true;
               
            });

      },
      getOrderShopSetting(){
            const shopObjectId = `${localStorage.getItem('shopObjectId')}`
            let params = {
                shopObjectId: shopObjectId,
            }
            const headers = {
                shopObjectId: shopObjectId,
            }
            local({
                url: "/api/v3.0/shop/ordershopsetting",
                params: params,
                headers: headers,
                method: "get",
            }).then((res) => {
                if(res.data.error.code == 0){
                    let isGrabOrderEnabled  = res.data.data.isGrabOrderEnabled
               
                    if(isGrabOrderEnabled!== undefined && isGrabOrderEnabled !== null && isGrabOrderEnabled!==''){
                        this.isGrabOrderEnabled = isGrabOrderEnabled
                        // this.merchantID = res.data.data.grab.merchantID || '';
                        if(isGrabOrderEnabled){
                            this.isNext = true
                        }
                    }else{
                        this.isGrabOrderEnabled = false
                        this.isNext = false
                    }
                    
                }else{
                    this.isGrabOrderEnabled = false
                    this.isNext = false
                }    
               
            }).catch((error) => {
                console.log(error);
            });
      },
      getStoreIntagrationAble(){
        const shopObjectId = this.shopObjectId; 
        const headers = { shopObjectId }; 
        let params = {shopObjectId}
       
        local.get('/api/v3.0/grab/store/integration/valid', { headers ,params})
        .then((res) => {
            if(res.data.data.valid !== undefined && res.data.data.valid !== null){
                this.merchantID = ''
                this.valid = res.data.data.valid;
                if(!this.valid){
                    this.getStoreConfiguration()
                    this.getOrderShopSetting()
                }else{
                    this.getStoreConfiguration()
                    this.getOrderShopSetting()
                }
                this.isNext = false
            }else{
                this.valid = false;
             
                this.getStoreConfiguration()
            }
        })
        .catch((error) => {
            console.error("Error fetching sales hours data:", error);
        })
        .finally(() => {
            this.loading = true;
            this.loadingButton = true;
        });
      }
    },
  }
  </script>
  
  <style>
  .nav-link {
    display: block;
    padding: 0.3rem 0.63rem;
  }
  .card-info{
    background: rgba(0, 0, 0, 0.03);
    position: inherit;
    padding: 1px 1px;
    border: 1px solid;
    border-color: #d8dbe0;
  }

  .step-tabs {
  width: 100%;
  margin: 0 auto;
}

.steps {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: space-between;
  border-bottom: 2px solid #ccc;
}

.step {
  padding: 10px 20px;
  cursor: pointer;
  position: relative;
}

.step.active {
  color: white;
  background-color: #007bff;
  border-radius: 5px 5px 0 0;
}

.step::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #ccc;
  bottom: 0;
  left: 0;
}

.step.active::after {
  background-color: #007bff;
}

.content {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 0 5px 5px 5px;
  margin-top: -2px;
}

.tab {
  display: none;
}

.tab.active {
  display: block;
}
.step.completed {
  background-color: #e0e0e0;
  color: #666;
}
.step.active {
  background-color: #007bff;
  color: white;
}

  </style>
  